$(function () {
    $(window).on('load resize', function(){
        let h_box_scroll = $('#box_scroll').innerHeight();
        $('#box_main').css('padding-bottom', h_box_scroll + 'px');
    });

    $(window).on('scroll load', function(){
        let banner = $('.list-banner').innerHeight();
        var scroll = $(window).scrollTop();
        if (scroll > banner) {
            $('.navbar').css('background', '#54545494');
        }else{
            $('.navbar').css('background', 'transparent');
        }
    });
});
